import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CenterLogo from "../components/CenterLogo"
import Logo from "../components/Logo"
import styled from "styled-components"
import ImgPolish from "../images/Production/polishing-825.jpg"
import ImgOffice825 from "../images/Production/office-825.jpg"
import LeftLogo from "../components/LeftLogo"
import PaddingLeftLogo from "../components/PaddingLeftLogo"
import H2 from "../components/H2"
import Bg from "../components/Bg"


import ContactFormer from "../components/ContactFormer"
const Relative = styled.div`
  position: relative;
`



const Circle = styled.div`
  background: #fff;
  border-color: grey;
  display: flex;
  flex-direction: column;
  width: 96%;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  min-height: 18vh;
  @media (max-width: 768px) {
    min-height: 26vh;
  }
`
const ExtBtnLink = styled.a`
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0px;
  font-family: Arial;
  color: #515151;
  font-size: 30px;
  background: transparent;
  padding: 10px 20px 10px 20px;
  border: solid #515151 1px;
  text-decoration: none;

  :hover {
    background: #292929;
    text-decoration: none;
    color: #fff;
  }
`
const BgByj = styled.div`
  background-repeat: no-repeat;
  min-height: 60vh;
  background-size: cover;
  background-position: center;
  background-image: url("https://res.cloudinary.com/dh9k0znwt/image/upload/v1679454819/Page%205%20Production/Production_revise_gmasno.jpg");
  h1 {
    padding-top: 20vh;
  }
`
const BgByj2 = styled.div`
  background-repeat: no-repeat;
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-image: url("https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528318/Page%205%20Production/5_Photo_qd9q9i.jpg");
  h1 {
    padding-top: 20vh;
  }
`
const ProductionPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="flex flex-wrap py-16 bg-white xl:pt-32 pt-24">
      <div className="item w-full h-auto text-center md:pt-9 py-3  2xl:pt-4">
        <BgByj></BgByj>
      </div>
    </div>
    <div className="flex flex-wrap xl:py-16 pb-16 bg-white">
      <div className="item w-full h-auto text-center md:py-9 py-0 pb-3">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>
          <H2 className="md:text-6xl text-4xl md:pt-0 pt-4">Production</H2>
        </Relative>
      </div>
    </div>
    <div className="container mx-auto">
      <div className="flex flex-wrap">
        <div className="item  w-full md:w-full lg:w-1/2 h-full sm:order-1  md:order-1 lg:order-1">
          <img
            className="object-contain md:object-scale-down f-r"
            alt="a changer"
            src={ImgOffice825}
          />
        </div>
        <div className="item w-full md:w-full lg:w-1/2 h-auto order-2  md:order-2  lg:order-2   md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 xl:py-2">
              Factory{" "}
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            Established in 1989, Advance Manufacturers is one of the leading
            jewelry manufacturers in Thailand. Over the last three decades, the
            factory’s capabilities continue to evolve as we continue to learn
            and adapt to new manufacturing technologies. As of January 2021, our
            production capacity is 3,000,000 pieces of gold, silver and brass
            jewelry per annum.
          </p>{" "}
          <div className="grid place-items-center">
            <ExtBtnLink
              href="https://www.advmfr.com"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Our Factory
            </ExtBtnLink>
          </div>
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-auto order-4  md:order-4 lg:order-3 md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 xl:py-2">
              Philosophy{" "}
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-6">
            <span className="text-4xl font-bold">
              Innovation Tradition and Integrity
            </span>
            <br /> <br />
            At Advance Manufacturers Co., Ltd, we offer high quality jewelry at
            competitive prices while maintaining consistent quality and reliable
            service. We are wholeheartedly committed to pursuing the vision and
            satisfaction of our clients while maintaining long lasting business
            relationships based on trust. It is our belief that the right
            balance of innovation, tradition and integrity is what sets us apart
            from other manufacturers.
          </p>
        </div>

        <div className="item  w-full md:w-full lg:w-1/2 h-full order-3  md:order-3 lg:order-4">
          <img
            className="object-contain md:object-scale-down f-l"
            alt="a changer"
            src={ImgPolish}
          />
        </div>
      </div>{" "}
    </div>{" "}
    <div className="container mx-auto px-6 md:px-16 text-center py-12">
      <div className="item w-full h-auto text-center py-9 pt-16 pb-16">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>
          <H2 className="md:text-6xl text-4xl pt-5 md:pt-0">factory</H2>
        </Relative>
      </div>
      <div className="flex flex-wrap -mx-2 overflow-hidden sm:-mx-2 md:-mx-1 lg:-mx-1 xl:-mx-1">
        <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
          <Circle>
            <p className="text-4xl font-extrabold pt-14 md:pt-14">1989</p>
            <p className="text-2xl py-6">Established </p>
          </Circle>
        </div>
        <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
          <Circle>
            <p className="text-4xl font-extrabold pt-14 md:pt-14">
              3 <br />
              Mio pieces
            </p>
            <p className="text-2xl py-2">Yearly produced jewelry</p>
          </Circle>
        </div>
        <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
          <Circle>
            <p className="text-4xl font-extrabold pt-14 md:pt-14 ">
              600
              <br />
              models
            </p>
            <p className="text-2xl py-2 px-4">Monthly model making capacity</p>
          </Circle>
        </div>
        <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
          <Circle>
            <p className="text-4xl font-extrabold pt-14 md:pt-14">450</p>
            <p className="text-2xl py-2">
              Number <span className="block">of</span> employees
            </p>
          </Circle>
        </div>
        <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/5 xl:my-1 xl:px-1 xl:w-1/5">
          <Circle>
            <p className="text-4xl font-extrabold pt-14 md:pt-14">
              130 <span className="text-3xl font-extrabold pt-14">clients</span>
              <br />
              <span className="text-3xl font-extrabold pt-14">
                in
                <br />
              </span>{" "}
              35{" "}
              <span className="text-3xl font-extrabold pt-14">countries</span>
            </p>
          </Circle>
        </div>
      </div>
    </div>
    <div className="flex flex-wrap  bg-white">
      <div className="item w-full h-auto text-center ">
        <BgByj2 />
      </div>
    </div>
    <Bg>
      <ContactFormer />
    </Bg>
  </Layout>
)

export default ProductionPage
